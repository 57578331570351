
  import Snackbar from '@/components/Snackbar'
  import common from '@/mixins/common'
  import { add_participant_to_project } from '@/store/user'
  import { callable } from '@/plugins/serverless'
  export default {
    components: { Snackbar },
    mixins: [common],
    middleware: ['authenticated-access'],
    data() {
      return {
        version: require('/package.json').version,
        clipped: false,
        drawer: false,
        miniVariant: false,
        title: 'SEEQ'
      }
    },
    created() {
      console.log('default-layout:created')
    },
    async mounted() {
      this.setup_color_scheme()
      this.log('app-load', this.user.name, this.user.id)
      if (this.user?.active.toLowerCase().trim() == 'new')
        this.$router.push(`/users/${this.user.id}/sign-up`)
      else if (sessionStorage.project) {
        const project_id = sessionStorage.project
        this.log(`adding ${this.user.username} to project`, project_id)
        await add_participant_to_project(this.user.id, project_id)
        sessionStorage.removeItem('project')
        this.sync_relations()
        this.$router.push(`/users/${this.user.id}/${project_id}`)
      } else if (!sessionStorage.relations) {
        this.sync_relations()
      }
    },
    methods: {
      async sync_relations() {
        console.log('sync-my-relations')
        await callable('relations')()
        sessionStorage.relations = true
      },
      view_user() {
        this.$router.push(`/users/${this.user.id}`)
      },
      view_company() {
        this.$router.push(`/companies/${this.user.companyIds[0]}`)
      },
      view_terms() {
        window.location.href = 'https://sharemorestories.com/terms-of-use/'
      },
      view_privacy() {
        window.location.href = 'https://sharemorestories.com/privacy-policy/'
      }
    }
  }
